
import { ConsultasFeature, FavoritosFeature, FiltrosFeature, NotificacionFeature } from "shared-components/Components/CustomIcons/MoveToTheApp/web";
import "./styles.less";
import { useContext } from "react";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { appData } from "./appsData";
import { useTheme } from "shared-components/Styles/ThemeHook";
import { firstLetterToUppercase } from "src/utils/Functions";

const BannerAppCampaign = () => {
    const { country_code, site_name, translations } = useContext(ConfigStateContext);
    const appLinks = appData[country_code ?? "UY"]
    const { theme } = useTheme();

    const dataApp = {
        features: [
            {
                img: <NotificacionFeature color={theme.colors.primaryColor} />,
                text: `${firstLetterToUppercase(translations.recibe)} notificaciones a medida que aparezcan nuevas oportunidades.`
            },
            {
                img: <FiltrosFeature color={theme.colors.primaryColor} />,
                text: `${firstLetterToUppercase(translations.filtra)} de la manera más precisa y ${translations.encuentra} tu casa más fácil.`
            },
            {
                img: <ConsultasFeature color={theme.colors.primaryColor} />,
                text: `${firstLetterToUppercase(translations.consulta)} directamente desde la app con respuestas en tiempo real.`
            },
            {
                img: <FavoritosFeature color={theme.colors.primaryColor} />,
                text: `${firstLetterToUppercase(translations.guarda)} tus favoritos y ${translations.recibe} notificaciones si bajan de precio.`
            }
        ],
        phoneImg: {
            CO: {
                mobile: "https://cdn1.infocasas.com.uy/web/6585c872c24cb_mockup-mobile-portal-fincaraiz.png",
                desktop: "https://cdn1.infocasas.com.uy/web/6585c87296843_mockup-qr-portal-fincaraiz.png"
            },
            UY: {
                mobile: "https://cdn2.infocasas.com.uy/web/th.outside12200x480.646baea82b82e_mobile_mini_app_mobile.png",
                desktop: "https://cdn2.infocasas.com.uy/web/th.outside1200x480.646bac6a8b8fd_qr.png"
            }
        }
    }

    const phoneImg = dataApp.phoneImg[country_code ?? "UY"]

    return (
        <section className="contenedor">
            <div className="bannerAppCampaign">
                <div className="bannerAppCampaign__features">
                    <p className="body body-2 body-bold medium">
                        ¡{firstLetterToUppercase(translations.encuentra)} tu casa con la app de {site_name}!
                    </p>
                    <h3 className="heading heading-2 high">Tu nuevo hogar a una descarga de distancia</h3>
                    <ul className="bannerAppCampaign__features--items">
                        {dataApp.features.map((e, i) => {
                            return <li key={`appFeature-${i}`} className="body body-1 body-semibold medium">{e.img}{e.text}</li>
                        })}
                    </ul>
                    <div className="bannerAppCampaign__features__links">
                        {appLinks.map((e, i) => { return (<a key={`button-link-${i}`} href={e.link} target='_blank'><img width="141" height="43" src={e.img} alt={'Botón a la tienda de aplicaciones de ' + e.name} /></a>) })}
                    </div>
                </div>
                {phoneImg && <div className="bannerAppCampaign__image">
                    <img width="263" height="383" className="bannerAppCampaign__image--mobile" src={phoneImg.mobile} alt="imagen de un Celular con la app de Infocasas" />
                    <img width="263" height="383" className="bannerAppCampaign__image--desktop" src={phoneImg.desktop} alt="imagen de un celular con la app de infocasas y un QR para ir a la tienda de aplicaciones" />
                </div>}
            </div>
            <div className="orangeBubble"></div>
        </section>
    )
}

export default BannerAppCampaign