import "./styles.less";

import { Button, Col, Form, Row, Typography } from "antd";
import {
	KeywordLocation,
	setRecientLocations,
} from "../../../Components/Filters/KeywordLocation/web";
import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { isTemporal, showPropertyType } from "../../../Utils/Functions";

import { OperationType } from "../../../Components/Filters/OperationType/web";
import { PropertyType } from "../../../Components/Filters/PropertyType/web";
import { TemporalFilter } from "../../../Components/Filters/TemporalFilter/web";
import { encodeHashUrl } from "../../../Utils/Functions";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useHomeFilters } from "./HomeFilters.hook";
import { useRouter } from "next/router";
import { useTheme } from "../../../Styles/ThemeHook";
// import { useGoogleAnalytics } from "shared-components/GlobalHooks/web/GoogleAnalytics.hook";
import { useTemporalFilter } from "shared-components/Components/Filters/TemporalFilter/TemporalFilter.hook";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import Show from "src/Components/Show/web";
import { LoadingIcon } from "shared-components/Components/CustomIcons/LoadingIcon/LoadingIcon";

import { SearchIcon } from "shared-components/Components/CustomIcons/SearchIcon/web";
import { Modal } from "shared-components/Components/Modal/web";
import { fetchSearchResults } from "src/services/search";
import useLocationExtraData from "src/hooks/locationExtraData/useLocationExtraData";
import { getFormattedFilters } from "src/utils/Functions";
import { PropStates } from "shared-components/Components/Filters/PropStates/web";

export const HomeFilters = () => {
	const { filters, changeFilters, filtersTags, search } = useHomeFilters();
	const { country_code } = useContext(ConfigStateContext);

	const doSearch = () => search.send({ variables: { params: getFormattedFilters(filters) } });

	const [location, setLocation] = useState([]);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [modalError, setModalError] = useState(false);
	const [searchByCode, setSearchByCode] = useState("");
	const [keyword, setKeyword] = useState("");
	const { setLocationText } = useLocationExtraData();

	const getPropertyByCode = async () => {
		fetchSearchResults({
			rows: 100,
			params: {
				id: searchByCode,
			},
			page: 1,
			source: 10,
		})
			.then(res => {
				if (res?.property?.link) {
					window.location.href = res.property.link;
				} else {
					setModalError(true);
				}
			})
			.catch(e => {
				console.log(e);
				return { data: [], property: null };
			});
	};

	const screens = useBreakpoint();
	const { theme } = useTheme();
	const router = useRouter();

	const showOperationFilter = true;
	const showTemporalFilter = isTemporal(filters.operation_type_id) && country_code !== "CO";
	const showPropertyFilter = showPropertyType(filters.operation_type_id) || country_code === "CO";

	const { setShowSeasonInput, showSeasonInput } = useTemporalFilter();

	const handleChangeSeason = show => {
		setShowSeasonInput(show);
	};

	const handleLocations = data => {
		setLocation(data);
	};

	const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
		const inputValue = event.target.value;
		setSearchByCode(inputValue);
	};

	const handleSearch = () => {
		if (location.length > 0) {
			const firstLocation = location[0];

			changeFilters({
				locations: [
					{
						text: "locations",
						value: firstLocation,
					},
				],
				searchstring: null,
			});

			setLocationText(keyword);
		} else {
			if (keyword) {
				changeFilters({
					searchstring: { text: keyword, value: keyword },
					locations: [],
				});
			} else {
				search.send({ variables: { params: getFormattedFilters(filters) } });
			}
		}
	};

	useEffect(() => {
		if (
			filters.searchstring != null ||
			filters.maxPrice != null ||
			(filters.locations && !!filters.locations.length)
		) {
			search.send({ variables: { params: getFormattedFilters(filters) } });
		}
	}, [filters.searchstring, filters.maxPrice, JSON.stringify(filters.locations)]);

	useEffect(() => {
		if (search.response.data && !search.response.loading) {
			setRecientLocations(filtersTags.neighborhood_id, filtersTags.estate_id);

			router.push(
				{
					pathname: "/searchPage",
					query: { hashed: encodeHashUrl({ filters: filtersTags }) },
				},
				search.response.data.searchUrl.url
			);
		}
	}, [search.response]);

	return (
		<Form onFinish={doSearch} className="home-filters home-filters-IC">
			<Row
				justify={"center"}
				gutter={[
					{
						xs: theme.spacing.mdSpacing,
						sm: theme.spacing.mdSpacing,
						lg: 0,
					},
					{
						xs: theme.spacing.mdSpacing,
						sm: theme.spacing.mdSpacing,
						lg: theme.spacing.smSpacing,
					},
				]}>
				{/* OPERTATION TYPE FILTER */}
				{showOperationFilter && (
					<Col
						xs={showTemporalFilter ? 24 : 12/*  : filters.operation_type_id === 1 ? 12 : 24 */}
						sm={showTemporalFilter ? 24 : 12/*  : filters.operation_type_id === 1 ? 12 : 24 */}
						order={0}
						lg={22}>
						<OperationType
							filterChanged={changeFilters}
							home
							inputType={screens.lg ? "buttons" : "select"}
							handleChangeSeason={handleChangeSeason}
							showSeasonInput={showSeasonInput}
						/>
					</Col>
				)}

				{/* PROPERTY TYPE FILTER */}
				{showPropertyFilter && (
					<Col xs={12} sm={12} lg={6} className="big-input" order={3}>
						<PropertyType filterChanged={changeFilters} borderRadio={true/* filters.operation_type_id===2 */} />
					</Col>
					
				)}
				{/* PROPERTY STATUS FILTER */}
				{/* {filters.operation_type_id=== 1 && (
					<Col xs={12} sm={12} lg={6} className="big-input" order={2}>
						<PropStates inputType="dropdown" />
					</Col>
				)} */}
					
				{/* TEMPORAL FILTER */}
				{showTemporalFilter && (
					<Col xs={24} sm={24} lg={10} className="big-input temporal-filter-container" order={4}>
						<TemporalFilter
							filterChanged={changeFilters}
							showSeasonInput={showSeasonInput}
							handleChangeSeason={handleChangeSeason}
						/>
					</Col>
				)}

				<Col flex={"1"} className="big-input" order={5}>
					<Row gutter={0}>
						<Col flex={"1"}>
							{/* KEYWORD - LOCATION FILTER */}
							<KeywordLocation
								filterChanged={changeFilters}
								searchByReference={true}
								searchByProject={false}
								handleLocations={handleLocations}
								locations={location}
								changeKeyword={setKeyword}
								isSearchForHome={true}
							/>
						</Col>
						<Col className={"search-button-container"}>
							<Button
								className="search-button superPrimary btn-primary"
								type="noStyles"
								onClick={handleSearch}>
								{search.response.loading ? (
									<LoadingIcon classes="loading-icon" />
								) : (
									<SearchIcon />
								)}
							</Button>
						</Col>
					</Row>
					<Show when={country_code === "CO"}>
						<Row gutter={0} justify="end">
							<Button
								className="btn-primary"
								style={{
									marginTop: "16px",
									color: "#082645",
								}}
								onClick={() => setIsModalOpen(true)}>
								Buscar por código
							</Button>
							<Modal
								show={isModalOpen}
								title="Buscar por código"
								footer={
									<footer className="modal-footer-container">
										<Button
											className="btn-primary"
											disabled={!searchByCode}
											type="primary"
											style={{ color: searchByCode ? "#082645" : "" }}
											onClick={getPropertyByCode}>
											Buscar
										</Button>
										<Button
											className="btn-secondary"
											onClick={() => {
												setIsModalOpen(false);
												setModalError(false);
												setSearchByCode("");
											}}>
											Cancelar
										</Button>
									</footer>
								}
								onClose={() => setIsModalOpen(false)}>
								<Typography.Title className="home-description-modal" level={5}>
									Ingresa el código de Fincaraíz del inmueble que quieres
									encontrar
								</Typography.Title>
								<input
									type="text"
									placeholder="Código Fincaraíz"
									className="search-departaments"
									style={{ marginBottom: "4px" }}
									value={searchByCode}
									onChange={handleSearchChange}
								/>
								<Show when={modalError}>
									<Typography.Text
										style={{
											paddingLeft: "8px",
											color: "#EA3E4F",
											textAlign: "left",
										}}>
										Código incorrecto o no existe
									</Typography.Text>
								</Show>
							</Modal>
						</Row>
					</Show>
				</Col>
			</Row>
		</Form>
	);
};