export const appData = {
    "CO": [
        {
            img: "https://cdn2.infocasas.com.uy/web/64401d3288d5f_google.png",
            name: 'Google Play',
            link: 'https://play.google.com/store/apps/details?id=co.com.fincaraiz.app'
        },
        {
            img: "https://cdn2.infocasas.com.uy/web/64401d1329fdd_apple.png",
            name: 'App Store',
            link: 'https://itunes.apple.com/co/app/fincaraiz.com.co/id1068634301'
        },
        {
            img: "https://cdn1.infocasas.com.uy/web/6585c64f882d9_disp-app-gallery-huawei-recorte.png",
            name: 'App Gallery',
            link: 'https://appgallery.huawei.com/#/app/C101202375'
        }
    ],
    "UY": [
        {
            img: "https://cdn2.infocasas.com.uy/web/64401d3288d5f_google.png",
            name: 'Google Play',
            link: 'http://play.google.com/store/apps/details?id=uy.com.infocasas.infoapp'
        },
        {
            img: "https://cdn2.infocasas.com.uy/web/64401d1329fdd_apple.png",
            name: 'App Store',
            link: 'http://itunes.apple.com/uy/app/infocasas/id1126880888?mt=8'
        }
    ],
}